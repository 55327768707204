import type { TrackingEvent } from '@archipro-website/tracker';
import { useTracker } from '@archipro-website/tracker';
import { useSharedTiles } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';
import { getSourceSection } from '~/utils/get-source-section';

export const useCategoryTilesTracking = (
    gridRef: React.RefObject<HTMLElement>
) => {
    const tracker = useTracker();
    const trackingConfig = {
        onClick: (
            targetRef: React.RefObject<HTMLElement>,
            itemID?: number,
            professionalID?: number,
            section?: string
        ) => {
            const logEvent = (eventName: TrackingEvent) => {
                tracker.log(eventName, {
                    url: new URL(window.location.href),
                    data: {
                        LibraryItemID: itemID,
                        ProfessionalID: professionalID,
                    },
                });
            };

            switch (section) {
                case 'CategoryPageRecentlyViewed':
                    logEvent('recentlyViewedTileClick');
                    break;

                case 'ProductRange':
                    logEvent('ProductRangeClick');
                    break;

                case 'ProductFeaturedProjects':
                    logEvent('ProductProjectClick');
                    break;

                case 'ProductRelated':
                    // we don't have any tracking for this yet
                    break;

                case 'ProductInstallers':
                    logEvent('ProductProfessionalInstallerClick');
                    break;

                case 'ProjectUsedProducts':
                    logEvent('ProductUsedProjectClick');
                    break;

                case 'ProfessionalProducts':
                    logEvent('ProfessionalProductClick');
                    break;

                case 'ProjectUsedProfessionals':
                    logEvent('ProfessionalUsedProjectClick');
                    break;

                case 'ProjectRelated':
                    logEvent('MoreProjectsFromClick');
                    break;

                case 'ProfessionalProjects':
                    logEvent('ProfessionalProjectClick');
                    break;

                default:
                    const gridData = getGridPosition(gridRef, targetRef);

                    if (gridData) {
                        const { row, column, columnsPerRow, position } =
                            gridData;
                        tracker.log('directoryTileClick', {
                            url: new URL(window.location.href),
                            data: {
                                LibraryItemID: itemID,
                                ProfessionalID: professionalID,
                                // format: Row,Column,CountPerRow,AbsolutePosition
                                ExtraData: `${row},${column},${columnsPerRow},${position}`,
                            },
                        });
                    }
            }
        },

        onTileMediaSliderSlideChange: (
            direction: 'CarouselPrev' | 'CarouselNext',
            itemID?: number
        ) => {
            const sectionSource = getSourceSection();
            tracker.log(direction, {
                url: new URL(window.location.href),
                data: {
                    ExtraData: {
                        source: sectionSource,
                        libraryItemId: itemID,
                    },
                },
            });
        },
    };

    useSharedTiles({
        trackingConfig,
    });
};

/**
 * Calculates the position of an element within a CSS Grid layout.
 *
 * This function determines:
 * - The row and column position of the target element
 * - The total number of columns in the grid
 * - The absolute position of the element in the grid sequence
 *
 * The calculation takes into account:
 * - Grid column and row gaps
 * - The relative position of the target element to the grid container
 * - The dimensions of both the grid and target element
 *
 * @param {React.RefObject<HTMLElement>} gridRef - Reference to the grid container element
 * @param {React.RefObject<HTMLElement>} targetRef - Reference to the target element within the grid
 * @returns {Object | undefined} Position data containing:
 *   - column: The column number (1-based)
 *   - row: The row number (1-based)
 *   - columnsPerRow: Total number of columns in the grid
 *   - position: Absolute position in the grid sequence (1-based)
 *   Returns undefined if either reference is invalid
 */
const getGridPosition = (
    gridRef: React.RefObject<HTMLElement>,
    targetRef: React.RefObject<HTMLElement>
) => {
    const gridElement = gridRef.current;
    const element = targetRef.current;

    if (!element || !gridElement) return;

    const gridStyles = window.getComputedStyle(gridElement);

    // Get grid properties
    const gridColumns = gridStyles.gridTemplateColumns.split(' ').length;
    const gridColumnGap = parseInt(gridStyles.columnGap) || 0;
    const gridRowGap = parseInt(gridStyles.rowGap) || 0;

    // Get element bounds
    const elementBounds = element.getBoundingClientRect();
    const gridBounds = gridElement.getBoundingClientRect();

    // Calculate cell size (including gap)
    const cellWidth = (gridBounds.width + gridColumnGap) / gridColumns;
    const cellHeight =
        (gridBounds.height + gridRowGap) / (gridBounds.height / cellWidth);

    // Calculate position
    const relativeLeft = elementBounds.left - gridBounds.left;
    const relativeTop = elementBounds.top - gridBounds.top;

    const column = Math.floor(relativeLeft / cellWidth) + 1;
    const row = Math.floor(relativeTop / cellHeight) + 1;

    return {
        column,
        row,
        columnsPerRow: gridColumns,
        position: gridColumns * (row - 1) + column,
    };
};
